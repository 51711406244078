import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { UspsComponent as UspsComponentType } from '@AuroraTypes';
import { Usps } from '@Components/Usps/Usps';

export const UspsComponent: React.FC<Omit<UspsComponentType, 'type'>> = ({ usps }) => {
  const { t } = useTranslation();

  return (
    <Usps
      items={usps}
      title={t('bookWithConfidence')}
    />
  );
};
